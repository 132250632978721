module.exports = [
  'fullstack',
  'Full stack -websovelluskehitys',
  'course',
  'helsingin yliopisto',
  'tietojenkäsittelytieteen osasto',
  'mooc',
  'mooc.fi',
  'full stack',
  'full stack open',
  'web-sovelluskehitys',
  'web',
  'houston',
  'houston inc',
  'websovelluskehitys',
  'web-sovellus',
  'React',
  'Redux',
  'Node.js',
  'Node',
  'MongoDB',
  'GraphQL',
  'REST',
  'REST api',
  'single page -sovellus',
  'ohjelmointi',
  'university of helsinki',
  'department of computer science',
  'web development',
  'software development',
  'web',
  'web application',
  'single page app',
  'programming',
];
